// constants/sellerConstants.js
export const SELECT_CURRENCY_REQUEST = 'SELECT_CURRENCY_REQUEST';
export const SELECT_CURRENCY_SUCCESS = 'SELECT_CURRENCY_SUCCESS';
export const SELECT_CURRENCY_FAIL = 'SELECT_CURRENCY_FAIL';

export const GET_SELECTED_CURRENCY_REQUEST = 'GET_SELECTED_CURRENCY_REQUEST';
export const GET_SELECTED_CURRENCY_SUCCESS = 'GET_SELECTED_CURRENCY_SUCCESS';
export const GET_SELECTED_CURRENCY_FAIL = 'GET_SELECTED_CURRENCY_FAIL';

export const TOGGLE_API_STATUS_REQUEST = 'TOGGLE_API_STATUS_REQUEST';
export const TOGGLE_API_STATUS_SUCCESS = 'TOGGLE_API_STATUS_SUCCESS';
export const TOGGLE_API_STATUS_FAIL = 'TOGGLE_API_STATUS_FAIL';
