// transactionConstants.js
export const USER_TRANSACTION_REQUEST = 'USER_TRANSACTION_REQUEST';
export const USER_TRANSACTION_SUCCESS = 'USER_TRANSACTION_SUCCESS';
export const USER_TRANSACTION_FAIL = 'USER_TRANSACTION_FAIL';

export const TRANSACTION_CREATE_REQUEST = 'TRANSACTION_CREATE_REQUEST';
export const TRANSACTION_CREATE_SUCCESS = 'TRANSACTION_CREATE_SUCCESS';
export const TRANSACTION_CREATE_FAIL = 'TRANSACTION_CREATE_FAIL';

export const GET_USER_TRANSACTIONS_TEST_REQUEST = 'GET_USER_TRANSACTIONS_TEST_REQUEST';
export const GET_USER_TRANSACTIONS_TEST_SUCCESS = 'GET_USER_TRANSACTIONS_TEST_SUCCESS';
export const GET_USER_TRANSACTIONS_TEST_FAIL = 'GET_USER_TRANSACTIONS_TEST_FAIL';
