// constants/sellerConstants.js
export const SELLER_ACCOUNT_REQUEST = "SELLER_ACCOUNT_REQUEST";
export const SELLER_ACCOUNT_SUCCESS = "SELLER_ACCOUNT_SUCCESS";
export const SELLER_ACCOUNT_FAIL = "SELLER_ACCOUNT_FAIL";

export const GET_SELLER_ACCOUNT_REQUEST = "GET_SELLER_ACCOUNT_REQUEST";
export const GET_SELLER_ACCOUNT_SUCCESS = "GET_SELLER_ACCOUNT_SUCCESS";
export const GET_SELLER_ACCOUNT_FAIL = "GET_SELLER_ACCOUNT_FAIL";

export const UPDATE_SELLER_ACCOUNT_REQUEST = "UPDATE_SELLER_ACCOUNT_REQUEST";
export const UPDATE_SELLER_ACCOUNT_SUCCESS = "UPDATE_SELLER_ACCOUNT_SUCCESS";
export const UPDATE_SELLER_ACCOUNT_FAIL = "UPDATE_SELLER_ACCOUNT_FAIL";

export const CREATE_BUSINESS_STATUS_REQUEST = "CREATE_BUSINESS_STATUS_REQUEST";
export const CREATE_BUSINESS_STATUS_SUCCESS = "CREATE_BUSINESS_STATUS_SUCCESS";
export const CREATE_BUSINESS_STATUS_FAIL = "CREATE_BUSINESS_STATUS_FAIL";
export const GET_BUSINESS_STATUS_REQUEST = "GET_BUSINESS_STATUS_REQUEST";
export const GET_BUSINESS_STATUS_SUCCESS = "GET_BUSINESS_STATUS_SUCCESS";
export const GET_BUSINESS_STATUS_FAIL = "GET_BUSINESS_STATUS_FAIL";
export const UPDATE_BUSINESS_STATUS_REQUEST = "UPDATE_BUSINESS_STATUS_REQUEST";
export const UPDATE_BUSINESS_STATUS_SUCCESS = "UPDATE_BUSINESS_STATUS_SUCCESS";
export const UPDATE_BUSINESS_STATUS_FAIL = "UPDATE_BUSINESS_STATUS_FAIL";

export const GET_BUSINESS_OWNER_DETAILS_REQUEST =
  "GET_BUSINESS_OWNER_DETAILS_REQUEST";
export const GET_BUSINESS_OWNER_DETAILS_SUCCESS =
  "GET_BUSINESS_OWNER_DETAILS_SUCCESS";
export const GET_BUSINESS_OWNER_DETAILS_FAIL =
  "GET_BUSINESS_OWNER_DETAILS_FAIL";
export const UPDATE_BUSINESS_OWNER_DETAILS_REQUEST =
  "UPDATE_BUSINESS_OWNER_DETAILS_REQUEST";
export const UPDATE_BUSINESS_OWNER_DETAILS_SUCCESS =
  "UPDATE_BUSINESS_OWNER_DETAILS_SUCCESS";
export const UPDATE_BUSINESS_OWNER_DETAILS_FAIL =
  "UPDATE_BUSINESS_OWNER_DETAILS_FAIL";

export const GET_BUSINESS_BANK_ACCOUNT_REQUEST =
  "GET_BUSINESS_BANK_ACCOUNT_REQUEST";
export const GET_BUSINESS_BANK_ACCOUNT_SUCCESS =
  "GET_BUSINESS_BANK_ACCOUNT_SUCCESS";
export const GET_BUSINESS_BANK_ACCOUNT_FAIL = "GET_BUSINESS_BANK_ACCOUNT_FAIL";
export const UPDATE_BUSINESS_BANK_ACCOUNT_REQUEST =
  "UPDATE_BUSINESS_BANK_ACCOUNT_REQUEST";
export const UPDATE_BUSINESS_BANK_ACCOUNT_SUCCESS =
  "UPDATE_BUSINESS_BANK_ACCOUNT_SUCCESS";
export const UPDATE_BUSINESS_BANK_ACCOUNT_FAIL =
  "UPDATE_BUSINESS_BANK_ACCOUNT_FAIL";

export const GET_SELLER_BVN_REQUEST = "GET_SELLER_BVN_REQUEST";
export const GET_SELLER_BVN_SUCCESS = "GET_SELLER_BVN_SUCCESS";
export const GET_SELLER_BVN_FAIL = "GET_SELLER_BVN_FAIL";
export const UPDATE_SELLER_BVN_REQUEST = "UPDATE_SELLER_BVN_REQUEST";
export const UPDATE_SELLER_BVN_SUCCESS = "UPDATE_SELLER_BVN_SUCCESS";
export const UPDATE_SELLER_BVN_FAIL = "UPDATE_SELLER_BVN_FAIL";

export const GET_SELLER_PHOTO_REQUEST = "GET_SELLER_PHOTO_REQUEST";
export const GET_SELLER_PHOTO_SUCCESS = "GET_SELLER_PHOTO_SUCCESS";
export const GET_SELLER_PHOTO_FAIL = "GET_SELLER_PHOTO_FAIL";
export const UPDATE_SELLER_PHOTO_REQUEST = "UPDATE_SELLER_PHOTO_REQUEST";
export const UPDATE_SELLER_PHOTO_SUCCESS = "UPDATE_SELLER_PHOTO_SUCCESS";
export const UPDATE_SELLER_PHOTO_FAIL = "UPDATE_SELLER_PHOTO_FAIL";

export const BUSINESS_OWNER_DETAIL_REQUEST = "BUSINESS_OWNER_DETAIL_REQUEST";
export const BUSINESS_OWNER_DETAIL_SUCCESS = "BUSINESS_OWNER_DETAIL_SUCCESS";
export const BUSINESS_OWNER_DETAIL_FAIL = "BUSINESS_OWNER_DETAIL_FAIL";

export const SELLER_BANK_ACCOUNT_REQUEST = "SELLER_BANK_ACCOUNT_REQUEST";
export const SELLER_BANK_ACCOUNT_SUCCESS = "SELLER_BANK_ACCOUNT_SUCCESS";
export const SELLER_BANK_ACCOUNT_FAIL = "SELLER_BANK_ACCOUNT_FAIL";

export const SELLER_BVN_REQUEST = "SELLER_BVN_REQUEST";
export const SELLER_BVN_SUCCESS = "SELLER_BVN_SUCCESS";
export const SELLER_BVN_FAIL = "SELLER_BVN_FAIL";

export const SELLER_PHOTO_REQUEST = "SELLER_PHOTO_REQUEST";
export const SELLER_PHOTO_SUCCESS = "SELLER_PHOTO_SUCCESS";
export const SELLER_PHOTO_FAIL = "SELLER_PHOTO_FAIL";

export const GET_ALL_SELLERS_ACCOUNT_REQUEST =
  "GET_ALL_SELLERS_ACCOUNT_REQUEST";
export const GET_ALL_SELLERS_ACCOUNT_SUCCESS =
  "GET_ALL_SELLERS_ACCOUNT_SUCCESS";
export const GET_ALL_SELLERS_ACCOUNT_FAIL = "GET_ALL_SELLERS_ACCOUNT_FAIL";

export const GET_ALL_BUSINESS_STATUS_REQUEST =
  "GET_ALL_BUSINESS_STATUS_REQUEST";
export const GET_ALL_BUSINESS_STATUS_SUCCESS =
  "GET_ALL_BUSINESS_STATUS_SUCCESS";
export const GET_ALL_BUSINESS_STATUS_FAIL = "GET_ALL_BUSINESS_STATUS_FAIL";

export const GET_ALL_BUSINESS_OWNERS_DETAILS_REQUEST =
  "GET_ALL_BUSINESS_OWNERS_DETAILS_REQUEST";
export const GET_ALL_BUSINESS_OWNERS_DETAILS_SUCCESS =
  "GET_ALL_BUSINESS_OWNERS_DETAILS_SUCCESS";
export const GET_ALL_BUSINESS_OWNERS_DETAILS_FAIL =
  "GET_ALL_BUSINESS_OWNERS_DETAILS_FAIL";

export const GET_ALL_SELLERS_BVN_REQUEST = "GET_ALL_SELLERS_BVN_REQUEST";
export const GET_ALL_SELLERS_BVN_SUCCESS = "GET_ALL_SELLERS_BVN_SUCCESS";
export const GET_ALL_SELLERS_BVN_FAIL = "GET_ALL_SELLERS_BVN_FAIL";

export const GET_ALL_SELLERS_PHOTO_REQUEST = "GET_ALL_SELLERS_PHOTO_REQUEST";
export const GET_ALL_SELLERS_PHOTO_SUCCESS = "GET_ALL_SELLERS_PHOTO_SUCCESS";
export const GET_ALL_SELLERS_PHOTO_FAIL = "GET_ALL_SELLERS_PHOTO_FAIL";

export const GET_ALL_SELLERS_BANK_ACCOUNT_REQUEST =
  "GET_ALL_SELLERS_BANK_ACCOUNT_REQUEST";
export const GET_ALL_SELLERS_BANK_ACCOUNT_SUCCESS =
  "GET_ALL_SELLERS_BANK_ACCOUNT_SUCCESS";
export const GET_ALL_SELLERS_BANK_ACCOUNT_FAIL =
  "GET_ALL_SELLERS_BANK_ACCOUNT_FAIL";

  export const GET_ALL_SELLERS_REQUEST = "GET_ALL_SELLERS_REQUEST";
  export const GET_ALL_SELLERS_SUCCESS = "GET_ALL_SELLERS_SUCCESS";
  export const GET_ALL_SELLERS_FAIL = "GET_ALL_SELLERS_FAIL";

  export const GET_SELLER_ACCOUNT_DETAIL_REQUEST = "GET_SELLER_ACCOUNT_DETAIL_REQUEST";
  export const GET_SELLER_ACCOUNT_DETAIL_SUCCESS = "GET_SELLER_ACCOUNT_DETAIL_SUCCESS";
  export const GET_SELLER_ACCOUNT_DETAIL_FAIL = "GET_SELLER_ACCOUNT_DETAIL_FAIL";

  export const VERIFY_SELLER_REQUEST = "VERIFY_SELLER_REQUEST";
  export const VERIFY_SELLER_SUCCESS = "VERIFY_SELLER_SUCCESS";
  export const VERIFY_SELLER_FAIL = "VERIFY_SELLER_FAIL";

