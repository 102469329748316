// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Paysofter.css */

.test-mode {
  top: 8px;
  right: 8px;
  background-color: red; 
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
}

.live-mode {
  top: 8px;
  right: 8px;
  background-color: green; 
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/Paysofter.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;EACE,QAAQ;EACR,UAAU;EACV,qBAAqB;EACrB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,QAAQ;EACR,UAAU;EACV,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":["/* Paysofter.css */\r\n\r\n.test-mode {\r\n  top: 8px;\r\n  right: 8px;\r\n  background-color: red; \r\n  color: white;\r\n  border-radius: 50%;\r\n  padding: 4px 8px;\r\n  font-size: 12px;\r\n  font-weight: bold;\r\n}\r\n\r\n.live-mode {\r\n  top: 8px;\r\n  right: 8px;\r\n  background-color: green; \r\n  color: white;\r\n  border-radius: 50%;\r\n  padding: 4px 8px;\r\n  font-size: 12px;\r\n  font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
